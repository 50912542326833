<template>
  <form
    autocomplete="off"
    @submit.prevent="submit"
  >
    <div class="row">
      <loading-notification :show="loading" />
      <error-notification :show="noMatch">
        <span>{{ $t('users.tabs.password.no_match') }}</span>
      </error-notification>
    </div>

    <div class="row">
      <div class="flex xs12 md6">
        <va-input
          color="info"
          v-model="form.password.value"
          :type="passwordFieldType"
          :label="$t('auth.password')"
          :error="!!form.password.errors.length"
          :error-messages="translatedErrors(form.password.errors)"
          @blur="validate('password')"
        >
          <va-button
            type="button"
            slot="append"
            style="margin-right: 0;"
            :icon="passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"
            @click="showPassword()"
            flat
            small
          />
        </va-input>
      </div>
      <div class="flex xs12 md6">
        <va-input
          color="info"
          v-model="form.confirm_password.value"
          :type="passwordFieldType"
          :label="$t('users.inputs.confirmPasswordInput')"
          :error="!!form.confirm_password.errors.length"
          :error-messages="translatedErrors(form.confirm_password.errors)"
          @blur="validate('confirm_password')"
        >
          <va-button
            type="button"
            slot="append"
            style="margin-right: 0;"
            :icon="passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"
            @click="showPassword()"
            flat
            small
          />
        </va-input>
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'

export default {
  name: 'change-password',
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    user: {
      type: Object,
      required: false,
    },
  },
  mixins: [validatorMixin],
  computed: {
    ...mapGetters(['currentLocale']),
    noMatch () {
      return this.newPassword !== this.confirmPassword && this.confirmPassword.length > 0
    },
    passwordFieldType () {
      return this.passwordVisible ? 'text' : 'password'
    },
  },
  data () {
    return {
      passwordVisible: false,
      newPassword: '',
      confirmPassword: '',
      form: {
        password: {
          value: '',
          validate: {
            required: true,
            minLength: 8,
          },
          errors: [],
        },
        confirm_password: {
          value: '',
          validate: {
            required: true,
            minLength: 8,
            equalsTo: {
              field: 'password',
              msg: '',
            },
          },
          errors: [],
        },
      },
    }
  },
  watch: {
    currentLocale (val) {
      this.validateAll()
    },
  },
  created () {
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    showPassword () {
      this.passwordVisible = !this.passwordVisible
    },
    async submit () {
      if (!this.user || !this.user.id) {
        return
      }

      this.validateAll()
      if (!this.formReady) return

      const user = {
        id: this.user.id,
        password: this.getFormValue('password'),
      }
      this.$emit('submit', user)
    },
  },
}
</script>
